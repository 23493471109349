/* Fonts */
@font-face {
  font-family: 'Bevellier';
  src: local('Bevellier'), url('./assets/Bevellier-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Bevellier';
  src: local('Bevellier'), url('./assets/Bevellier-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Habana Deco ML';
  src: url('./assets/Habana-Deco-ML.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ready player one';
  src: url('./assets/Ready-Player-One.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
